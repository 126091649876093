import React, { Component } from "react";
import "../App.css";
import Pdf from "../Pdffiles/MuhammadAsadyaseen.pdf";
// import logo from "../images/logo.png";

export default class Navbar extends Component {
  onResumeClick() {
    window.open(Pdf);
  }
  constructor() {
    super();
    this.state = {
      isScrolled: false,
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    if (window.scrollY > 100) {
      // You can adjust the scroll threshold as needed
      this.setState({ isScrolled: true });
    } else {
      this.setState({ isScrolled: false });
    }
  };
  render() {
    const walletBtnClasses = this.state.isScrolled
      ? "walletBtnscrolled"
      : "walletBtn";
    return (
      <div className=" ">
        <nav className="container navbar navbar-expand-lg mx-auto navbar-dark pt-4 p-0">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#collapsibleNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            {/* <img src={logo} style={{ width: "70px" }} /> */}
            <h1 className="networks">Codifyz</h1>

            <ul className="navbar-nav mx-auto v-center">
              <li className="nav-item active">
                <a className="nav-link2" href="#experience">
                  Experience
                </a>
              </li>
              <li className="nav-item active">
                <a className="nav-link3" href="#plan">
                  Project Plan
                </a>
              </li>

              <li className="nav-item active">
                <a className="nav-link4" href="#contact">
                  Portfolio
                </a>
              </li>
            </ul>
            <div className="v-center h-center">
              <div className="d-sm-flex d-block justify-content-center align-items-center text-center v-center">
                {/* <Social_3 /> */}
                {/* <a href='https://twitter.com/'><img src={Tweeter} className="pl-3" alt="tweeter" /></a>
                                <a href='https://www.instagram.com/'><img src={Instagram} className="pl-3" alt="instagram" /></a>
                                <a href='https://www.instagram.com/'><img src={Discord} className="pl-3" alt="discord" /></a> */}
                <a href={Pdf} target="_blank">
                  {/* onClick={this.onResumeClick} */}
                  <a
                    href="https://www.fiverr.com/s/YPbGoa"
                    target="_blank"
                    className={
                      walletBtnClasses +
                      " d-flex justify-content-center align-items-center mt-sm-0"
                    }
                  >
                    <p>Get In Touch</p>
                  </a>
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
